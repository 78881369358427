<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>
            {{ title }}
         </v-card-title>
         <v-card-text>
            <form>
               <v-row>
                  <v-col>
                     <v-text-field
                        v-model="nombre"
                        label="Nombre"
                        required
                        :error-messages="nombreErrors"
                        @input="$v.nombre.$touch()"
                        @blur="$v.nombre.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                     <v-switch v-model="estatus" true-value="S" false-value="N" label="Estatus" :disabled="!isEdit"></v-switch>
                  </v-col>
               </v-row>
               <v-text-field
                  v-model="username"
                  label="Username"
                  required
                  :error-messages="usernameErrors"
                  @input="$v.username.$touch()"
                  @blur="$v.username.$touch()"
               ></v-text-field>
               <v-text-field
                  v-model="password"
                  label="Contraseña"
                  :error-messages="passwordErrors"
                  :required="!isEdit"
                  :type="show_password ? 'text' : 'password'"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show_password = !show_password"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
               ></v-text-field>
               <v-select
                  v-model="rol"
                  label="Rol"
                  item-text="text"
                  item-value="value"
                  required
                  :return-object="true"
                  :items="roles"
                  :error-messages="rolErrors"
                  @input="$v.rol.$touch()"
                  @blur="$v.rol.$touch()"
               ></v-select>
               <v-select
                  v-model="sucursal"
                  label="Sucursal"
                  item-text="text"
                  item-value="value"
                  required
                  :return-object="true"
                  :items="sucursales"
                  :error-messages="sucursalErrors"
                  @input="$v.sucursal.$touch()"
                  @blur="$v.sucursal.$touch()"
               ></v-select>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength, alphaNum } from "vuelidate/lib/validators";

export default {
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(30),
         name: function () {
            return /^[a-zA-Z_ ]*$/.test(this.nombre);
         },
      },
      username: {
         alphaNum,
         required,
         maxLength: maxLength(50),
         minLength: minLength(10),
      },
      password: {
         required: function () {
            if (this.isEdit) {
               return true;
            } else if (this.password.trim().length > 0) {
               return true;
            } else {
               return false;
            }
         },
         minLength: minLength(10),
         maxLength: maxLength(30),
      },
      rol: {
         required: function () {
            return (this.rol.value + "").length > 0;
         },
      },
      sucursal: {
         required: function () {
            return (this.sucursal.value + "").length > 0;
         },
      },
   },
   data: function () {
      return {
         user_id: null,
         nombre: "",
         username: "",
         password: "",
         estatus: "S",
         rol: {
            value: "",
            text: "",
         },
         sucursal: {
            value: "",
            text: "",
         },
         dialog: false,
         show_password: false,
         isloading: false,
         roles: [],
         sucursales: [],
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertUser", {
                     nombre: this.nombre,
                     username: this.username,
                     password: this.password,
                     rol_id: this.rol.value,
                     sucursal_id: this.sucursal.value,
                  });
               } else {
                  if (this.password.trim() > 0) {
                     res = await this.$store.dispatch("updateUser", {
                        usuario_id: this.user_id,
                        nombre: this.nombre,
                        username: this.username,
                        password: this.password,
                        rol_id: this.rol.value,
                        sucursal_id: this.sucursal.value,
                        estatus: this.estatus,
                     });
                  } else {
                     res = await this.$store.dispatch("updateUser", {
                        usuario_id: this.user_id,
                        nombre: this.nombre,
                        username: this.username,
                        rol_id: this.rol.value,
                        sucursal_id: this.sucursal.value,
                        estatus: this.estatus,
                     });
                  }
               }
               if (res == true) {
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: async function (i) {
         try {
            this.dialog = true;
            this.isloading = true;
            await this.$store.dispatch("getRoles");
            await this.$store.dispatch("getSucursales");

            this.user_id = i.user_id;
            this.nombre = i.nombre;
            this.username = i.username;
            this.password = "";
            this.estatus = !i.user_id ? "S" : i.estatus;
            let rols = [];
            this.$store.state.roles.forEach(function (val) {
               rols.push({
                  value: val.rol_id,
                  text: val.nombre,
               });
            });
            this.roles = rols;
            this.rol = this.roles.filter((elem) => elem.text == i.rol)[0];
            if (!this.rol) {
               this.rol = {
                  value: "",
                  text: "",
               };
            }
            let sucus = [];
            this.$store.state.sucursales.forEach(function (val) {
               sucus.push({
                  value: val.sucursal_id,
                  text: val.nombre,
               });
            });
            this.sucursales = sucus;
            this.sucursal = this.sucursales.filter((elem) => elem.text == i.sucursal)[0];
            if (!this.sucursal) {
               this.sucursal = {
                  value: "",
                  text: "",
               };
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   computed: {
      isEdit() {
         if (this.user_id) {
            return true;
         } else {
            return false;
         }
      },
      title() {
         if (this.user_id) {
            return "Editar Usuario";
         } else {
            return "Crear Usuario";
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.nombre.name && errors.push("Debe contener caracteres alfabeticos");
         return errors;
      },
      usernameErrors() {
         const errors = [];
         if (!this.$v.username.$dirty) return errors;
         !this.$v.username.required && errors.push("El username es requerido");
         !this.$v.username.maxLength && errors.push("Debe contener maximo 50 caracteres");
         !this.$v.username.minLength && errors.push("Debe contener minimo 10 caracteres");
         !this.$v.username.alphaNum && errors.push("Debe contener caracteres alfanumericos");
         return errors;
      },
      passwordErrors() {
         const errors = [];
         if (!this.$v.password.$dirty) return errors;
         !this.$v.password.required && errors.push("La contraseña es requerida");
         !this.$v.password.minLength && errors.push("Debe contener minimo 10 caracteres");
         !this.$v.password.maxLength && errors.push("Debe contener maximo 30 caracteres");
         return errors;
      },
      estatusErrors() {
         const errors = [];
         if (!this.$v.estatus.$dirty) return errors;
         !this.$v.estatus.required && errors.push("Requerido");
         return errors;
      },
      rolErrors() {
         const errors = [];
         if (!this.$v.rol.$dirty) return errors;
         !this.$v.rol.required && errors.push("Requerido");
         return errors;
      },
      sucursalErrors() {
         const errors = [];
         if (!this.$v.sucursal.$dirty) return errors;
         !this.$v.sucursal.required && errors.push("Requerido");
         return errors;
      },
   },
};
</script>
