<template>
   <div>
      <v-tabs v-model="tab" align-with-title>
         <v-tabs-slider></v-tabs-slider>
         <v-tab>Usuarios</v-tab>
         <v-tab>Roles</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
         <v-tab-item>
            <Users />
         </v-tab-item>
         <v-tab-item :disabled="$permiso('consultar_roles')">
            <Roles />
         </v-tab-item>
      </v-tabs-items>
   </div>
</template>

<script>
import Users from "../components/Users/Users.vue";
import Roles from "../components/Users/Roles.vue";

export default {
   components: {
      Roles,
      Users,
   },
   data: () => {
      return {
         tab: null,
      };
   },
};
</script>
