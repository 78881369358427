<template>
   <div class="ma-6">
      <Rol @save="$refs.ok_noti.Show()" ref="rol" />
      <Dialog message="¿Desea eliminar el rol?" @result_ok="confirmDeleteItem()" ref="deleteItem" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <v-data-table :loading="isloading" :headers="headers" :items="$store.state.roles" :items-per-page="5" class="elevation-1">
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn @click="newItem()"><v-icon left>mdi-plus-circle</v-icon>Nuevo rol</v-btn>
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
            </v-toolbar>
         </template>
         <template v-slot:[`item.permisos`]="{ item }">
            <v-chip color="secondary" small v-for="elem in item.permisos" :key="elem.rol_id" class="ma-1">
               {{ elem.permiso.replaceAll("_", " ") }}
            </v-chip>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import Rol from "./Users/Rol.vue";

export default {
   components: {
      Rol,
   },
   data() {
      return {
         headers: [
            { text: "ID", value: "rol_id" },
            { text: "Rol", value: "nombre" },
            { text: "Permisos", value: "permisos" },
            { text: "Acciones", value: "actions" },
         ],
         itemToDelete: null,
         isloading: true,
      };
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getRoles");
            await this.$store.dispatch("getPermisos");
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async newItem() {
         if (this.$permiso("insertar_roles"))
            this.$refs.rol.show({
               rol_id: null,
               nombre: "",
               permisos: [],
            });
      },
      async editItem(item) {
         if (this.$permiso("actualizar_roles"))
            this.$refs.rol.show({
               rol_id: item.rol_id,
               nombre: item.nombre,
               permisos: item.permisos,
            });
      },
      async deleteItem(item) {
         if (this.$permiso("eliminar_roles")) {
            this.itemToDelete = item;
            this.$refs.deleteItem.Show();
         }
      },
      async confirmDeleteItem() {
         try {
            this.isloading = true;
            let res = null;
            res = await this.$store.dispatch("deleteRol", {
               rol_id: this.itemToDelete.rol_id,
            });
            if (res == true) {
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   created() {
      this.initialize();
   },
};
</script>

<style></style>
