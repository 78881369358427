<template>
   <div class="ma-6">
      <User @save="$refs.ok_noti.Show()" ref="user" />
      <Dialog message="¿Desea eliminar el usuario?" @result_ok="confirmDeleteItem()" ref="deleteItem" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <v-data-table :loading="isloading" :headers="headers" :items="$store.state.users" :items-per-page="5" class="elevation-1">
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn @click="newItem()"><v-icon left>mdi-plus-circle</v-icon>Nuevo usuario</v-btn>
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
            </v-toolbar>
         </template>
         <template v-slot:[`item.estatus`]="{ item }">
            <v-icon small v-if="item.estatus == 'S'">mdi-checkbox-marked-outline</v-icon>
            <v-icon small v-else>mdi-checkbox-blank-outline</v-icon>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import User from "./Users/User.vue"

export default {
   components: {
      User,
   },
   data() {
      return {
         headers: [
            { text: "ID", value: "user_id" },
            { text: "Nombre", value: "nombre" },
            { text: "Username", value: "username" },
            { text: "Rol", value: "rol" },
            { text: "Sucursal", value: "sucursal" },
            { text: "Estatus", value: "estatus" },
            { text: "Acciones", value: "actions" },
         ],
         itemToDelete: null,
         isloading: true,
      };
   },
   methods: {
      async initialize() {
         try {
            await this.$store.dispatch("getUsers");
            this.isloading = true;
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async newItem() {
         if (this.$permiso("insertar_usuarios"))
         this.$refs.user.show({
            user_id: null,
            nombre: "",
            username: "",
            password: "",
            rol: "",
            sucursal: "",
            estatus: "S",
         });
      },
      async editItem(item) {
         if (this.$permiso("actualizar_usuarios"))
         this.$refs.user.show({
            user_id: item.user_id,
            nombre: item.nombre,
            username: item.username,
            password: "",
            rol: item.rol,
            sucursal: item.sucursal,
            estatus: item.estatus
            });
      },
      async deleteItem(item) {
         if (this.$permiso("eliminar_usuarios")){
            this.itemToDelete = item;
            this.$refs.deleteItem.Show();
         }
      },
      async confirmDeleteItem() {
         try {
            this.isloading = true;
            let res = null;
            res = await this.$store.dispatch("deleteUser", {
               usuario_id: this.itemToDelete.user_id,
            });
            if (res == true) {
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
   },
   created() {
      this.initialize();
   },
};
</script>

<style></style>
